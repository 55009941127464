<template>
    <AppPaginatedGrid
        ref="grid"
        :service="service"
        permission="estrutura_cliente"
        newPath="/customers/new"
        editPath="/customers/"
        :extraActionItems="actionItems"
        @onToggleMenu="onToggleMenu"
        :expander="false"
        subtitle="Cliente"
        tooltip="Cadastro de clientes"
        :tipo="tipoFiltroEnum.SISTEMA"
        :modulo="moduloFiltroEnum.CLIENTES"
        descricao="FILTRO PADRÃO CLIENTES"
        nomeTelaDoManual="customers-list"
        origemHistoricoAcao="/customers"
    >
        <template #columns>
            <Column field="id" :sortable="true" sortField="customers.id" header="Cod."></Column>

            <Column field="customers.name" :sortable="true" sortField="customers.name" header="Cliente">
                <template #body="slotProps">
                    {{ slotProps.data.tradeName }}
                    <small title="Razão social" class="block text-500"> {{ slotProps.data.name }} </small>
                    <small title="Unidade" class="block text-500"> {{ slotProps.data.unidade }} </small>
                </template>
            </Column>

            <Column field="inscricao" :sortable="true" sortField="customers.inscricao" header="Inscrição">
                <template #body="slotProps">
                    {{
                        slotProps.data.tipoInscricao === 'CNPJ'
                            ? mCNPJ(slotProps.data.inscricao.substring(0, 10))
                            : slotProps.data.tipoInscricao === 'CPF'
                            ? mCPF(slotProps.data.inscricao)
                            : ''
                    }}
                </template>
            </Column>

            <Column field="created_at" :sortable="true" sortField="customers.created_at" header="Criado">
                <template #body="slotProps">
                    {{ $filters.formatDate(slotProps.data.createdAt) }}
                    <small class="block text-500"> por {{ slotProps.data.userCreate?.name }} </small>
                </template>
            </Column>

            <Column field="updated_at" :sortable="true" sortField="customers.updated_at" header="Atualizado">
                <template #body="slotProps">
                    {{ $filters.formatDate(slotProps.data.updatedAt) }}
                    <small class="block text-500"> por {{ slotProps.data.userUpdate?.name }} </small>
                </template>
            </Column>
            <Column field="classificacao" :sortable="true" sortField="customers.classificacao" header="Classificação"> </Column>
            <Column field="situacao" :sortable="true" sortField="customers.situacao" header="Situação"></Column>
        </template>
    </AppPaginatedGrid>
    <MetodosAtendimentoDialog
        v-if="showMetodoAtendimentoDialog"
        v-model:visible="showMetodoAtendimentoDialog"
        v-model:cliente="record"
        @onClose="showMetodoAtendimentoDialog = false"
    ></MetodosAtendimentoDialog>
</template>

<script>
import BaseService from '../../services/BaseService';
import { getCurrentCompany, getCurrentCustomers } from '@/services/store';
import { mCPF, mCNPJ } from '../../utils/Mask';
import MetodosAtendimentoDialog from './components/MetodosAtendimentoDialog.vue';
import ModuloFiltroEnum from '../../enums/ModuloFiltroEnum';
import TipoFiltroEnum from '../../enums/TipoFiltroEnum';

export default {
    components: {
        MetodosAtendimentoDialog
    },
    data() {
        return {
            tenantCustomers: [],
            service: null,
            mCPF,
            mCNPJ,
            record: {},
            showMetodoAtendimentoDialog: false,
            actionItems: [
                {
                    label: 'Métodos de Atendimento Dinâmicos',
                    icon: 'pi pi-list',
                    disabled: () => !this.$checkPermission('metodo_atendimento:view'),
                    command: () => this.openMetodosAtendimento(this.record)
                }
            ]
        };
    },
    mounted() {
        this.tenantCustomers = getCurrentCustomers();
    },
    computed: {
        tenant() {
            return getCurrentCompany();
        },
        currentCustomer() {
            return getCurrentCustomers();
        },
        moduloFiltroEnum() {
            return ModuloFiltroEnum;
        },
        tipoFiltroEnum() {
            return TipoFiltroEnum;
        }
    },
    watch: {
        tenant() {
            this.load();
        },
        currentCustomer() {
            this.tenantCustomers = getCurrentCustomers();
            this.load();
        }
    },
    created() {
        this.service = new BaseService('/customers');
    },
    methods: {
        async load() {
            this.$refs.grid.load();
        },
        openMetodosAtendimento() {
            this.showMetodoAtendimentoDialog = true;
        },
        onToggleMenu(event, data) {
            this.record = data;
        }
    }
};
</script>
